import React from 'react'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Styles from "../styles/components/PostItem.module.scss"
import clsx from "clsx"

export const PostItem = ( {
    post
}: any ) => {
    return (

        <li className={ clsx( Styles.post_item, post.frontmatter.featuredpost && Styles.featured_post_item ) }>
            <Link
            className={Styles.link}
                to={ post.frontmatter.path }>
                <article
                    className={ clsx( post.frontmatter.featuredpost && 'is-featured' ) }>
                    <header>
                        { post.frontmatter.featuredimage &&
                            <div className={ Styles.image_wrapper }>
                                <PreviewCompatibleImage
                                    imageInfo={ {
                                        image: post.frontmatter.featuredimage,
                                        alt: `featured image thumbnail for post ${ post.title }`,
                                    } } />
                            </div> }
                        <p className={ Styles.post_title }>
                            { post.frontmatter.title }
                        </p>
                    </header>
                    <p className={ Styles.description }>
                        {/* { post.excerpt } */ }
                        { post.frontmatter.description }
                    </p>
                </article>
            </Link>
        </li>
    )
}
